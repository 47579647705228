<!--
 * @Author: jiang
 * @Date: 2021-07-15 16:43:32
 * @Description:
-->
<template>
  <el-menu
    class="l-app-menu-horizontal"
    ref="menu"
    mode="horizontal"
    :default-active="defaultIndex"
    @select="onSelect"
  >
    <menu-item
      v-for="item in menuTree"
      :key="item.name"
      :data="item"
      :toBody="false"
    ></menu-item>
  </el-menu>
</template>

<script>
import MenuItem from './app-menu-item.vue'
import { mapGetters } from 'vuex'
import { treeFind } from '@/utils/tree'

export default {
  components: {
    MenuItem,
  },
  props: {
    mode: {
      type: String,
      default: 'vertical',
    },
  },
  data() {
    return {
      defaultIndex: null,
      menuArr:[]
    }
  },
  computed: {
    ...mapGetters('menu', ['menuTree']),
  },
  watch: {
    '$route.name': {
      handler(val) {
        this.defaultIndex = val
      },
      immediate: true,
    },
  },
  mounted(){

  },
  methods: {
    handlerGetItem(key) {
      return treeFind(this.menuTree, node => node.name === key, {
        children: 'list',
      })
    },
    onSelect(index) {
      if (this.defaultIndex === index) return
      const item = this.handlerGetItem(index)
      if (item && item.type === 'page') {
        this.$router.push({
          path: item.path.startsWith('/') ? item.path : `/${item.path}`,
          query: item.query,
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
// .l-app-menu-horizontal.el-menu--horizontal {
//   border-bottom: none;

//   /deep/ {
//     .el-submenu .el-submenu__title,
//     .el-menu-item {
//       height: 51px;
//       line-height: 51px;
//     }

//     .el-submenu__title i {
//       color: inherit;
//     }
//   }
// }
// .el-menu {
//   background-color: transparent;
// }

// /deep/ .el-submenu.is-active > .el-submenu__title {
//   color: #fff;
// }

// .el-menu--horizontal > /deep/ .el-submenu .el-submenu__title:hover {
//   background-color: transparent;
// }

// .el-menu--horizontal > /deep/ .el-menu-item:not(.is-disabled):hover,
// .el-menu--horizontal > /deep/ .el-menu-item:not(.is-disabled):focus {
//   background-color: transparent;
// }

// /deep/ .el-menu-item.is-active {
//   background-color: rgba($color: $color-primary, $alpha: 0.15);
// }
</style>
