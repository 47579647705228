<template>
  <b-section-scroll v-loading="loading">
    <!-- 详细信息 -->
    <div v-if="info.id" style="margin-bottom: 16px;">
      <div class="content-header">
        <div class="title">
          <div>
            <span class="g-mr-10">{{ info.title }}</span>
          </div>
        </div>
      </div>
      <div class="content-body">{{ info.content }}</div>
      <div>
          <div class="info-row"  v-if="info.media && info.media.length > 0">
            <div class="info-label">附件：</div>
            <div class="info-body">
              <div class="media-box">
                <c-media-list :list="info.media" :other="{type:'special','user_id':userId,'special_id':info.id,'task_id':info.id,'department_id':departmentId}"></c-media-list>
              </div>
            </div>
          </div>
    </div>


      <div class="info-row"
        v-if="(info.actionDepartments && info.actionDepartments.length > 0) || info.unActionDepartments">
        <div class="info-label">涉及部门：</div>
        <div class="info-body">
          <el-tag class="g-mr-5 g-mb-5" v-for="item in info.actionDepartments" :key="item.id" type="primary">
            {{ item.name }}</el-tag>
          <el-tag class="g-mr-5 g-mb-5" v-for="item in info.unActionDepartments" :key="item.id" type="danger">
            {{ item.name }}</el-tag>
        </div>
      </div>
      <div class="info-row" style="width: 100%;" v-if="departmentId==info.department_id&&info.parent_id!=585">
        汇总表：
        <s-media-list :list="info.specials_huizong" @deleteFile="deleteFile" ></s-media-list>
        <el-button type="primary" @click="dialogVisible=true" size="mini">上传汇总表</el-button>

      </div>
<!--      <div class="info-row" v-if="info.follow_users && info.follow_users.length">-->
<!--        <div class="info-label">汇报对象：</div>-->
<!--        <div class="info-body">-->
<!--          <el-tag class="g-mr-5 g-mb-5" v-for="item in info.follow_users" :key="item.id" type="info">-->
<!--            {{ item.name }}-->

<!--          </el-tag>-->
<!--        </div>-->
<!--      </div>-->
      <div class="content-time">
        <span class="g-mr-10">{{ info.department ? info.department.name : '' }}</span>
        <span class="g-mr-10">发布于：{{ info.created_at }} </span>
      </div>
    </div>
    <!-- 栏目类型 -->
 
    <el-tabs type="border-card" @tab-click="handleClick" >
      <el-tab-pane :lazy="true" :label="item.name" :name="i+''" v-for="(item,i) in columns" :key="i">

        <columnListBody :columnsId='item.id' :specialId="id" :columnName="item.name"> </columnListBody>
      </el-tab-pane>
    </el-tabs>
    <el-dialog
  title="提示"
  :visible.sync="dialogVisible"
  width="30%"
  >
  <div>
      <c-upload-media
            v-model="media_keys"
            :default="defaultMedia"
            flag="1"
            ref="mediaRef"
          ></c-upload-media>
  </div>
  <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="uploadFeil">确 定</el-button>
  </span>
</el-dialog>
  </b-section-scroll>

</template>

<script>
  import columnListBody from './column-list-body'
  import CMediaList from '@/components/upload/media-list/edit'
  import SMediaList from './edit'
  import {
    getSpecial,
    getAllColumn,
    createHuizong
  } from '@/api/special/index'
  import CUploadMedia from '@/components/upload/media'
  export default {
    components: {
      CMediaList,
      columnListBody,
      CUploadMedia,
      SMediaList
    },
    data() {
      return {
        praiseWhiteUrl: require('@/assets/icons/praise-white.png'),
        praiseGreyUrl: require('@/assets/icons/praise-grey.png'),
        id: null,
        task_id: null,
        loading: false,
        info: {},
        actionList: [],
        page: {
          current: 1,
          size: 15,
          total: 0,
        },
        boxHeight: 0,
        columns: [],
        activeName: 0,
        defaultMedia: [],
        media_keys: [],
        dialogVisible:false
      }
    },
    computed: {
      userId() {
        return this.$store.getters['user/id']
      },
      departmentId(){
        return this.$store.getters['user/deptId']
      }
    },
    watch: {
      '$route.query.id': {
        handler(id) {
          this.id = id
          this.task_id = this.$route.query.task_id
          this.fetchData()
          this.getAllColumn()
          console.log(this.$store.getters)

        },
        immediate: true,
      },
    },
    methods: {
      handleClick(tab, event) {

      },
      getAllColumn() {
        this.loading = true
        getAllColumn(this.id)
          .then(res => {
            this.columns = res
          })
          .finally(() => {
            this.loading = false
          })
      },
      fetchData() {
        this.loading = true
        getSpecial(this.id)
          .then(res => {
            this.info = res
          })
          .finally(() => {
            this.loading = false
          })
      },
      // 上传附件
      uploadFeil(){
        console.log(this.media_keys)
        const params = {
          specials_id:this.id,
          file_url:this.media_keys[0].url,
          file_name:this.media_keys[0].original_filename
          }
          createHuizong(params)
              .then(res => {
                // this.$message.success('修改成功')
                // this.$emit('success')
                if(res){
                  this.dialogVisible = false
                  this.fetchData()
                  this.$refs.mediaRef.fileList = []
                }

              })
              .finally(() => {

              })
      },
      deleteFile(){
        this.fetchData()
      },
      onCreate() {
        this.$refs.actionDetail.open({
          task_id: this.task_id,
          content: '',
          images: [],
          media_keys: [],
        })
      },
      onUpdate(item) {
        var labels = []
        item.labels.forEach(element => {
          labels.push(element.id)
        })

        this.$refs.actionDetail.open({
          id: item.id,
          special_id: item.special_id,
          content: item.content,
          images: item.images,
          media_keys: item.media,
          labels: labels,
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
  .media-box {
    padding: 10px;
  }

  .praise-icon {
    width: 13px;
    height: 13px;
    margin-right: 8px;
    vertical-align: middle;
  }

  .content-header {
    display: flex;
    align-items: center;
    padding: 10px 20px;

    .title {
      flex: 1;
      min-width: 0;
      margin-right: 20px;
      font-size: 20px;
      font-weight: bold;
      color: #2b2d42;
    }
  }

  .content-body {
    padding: 0 20px 10px;
    font-size: 16px;
    color: #666;
    word-break: break-all;
  }

  .content-time {
    display: inline-flex;
    padding: 10px 20px;
    font-size: 14px;
    color: #999;
    word-break: break-all;
  }

  .info-row {
    display: inline-flex;
    padding: 5px 20px;
    line-height: 30px;

    .info-label {
      margin-right: 10px;
      font-size: 14px;
      font-weight: 600;
      color: #444;
    }

    .info-body {
      flex: 1;
    }
  }
</style>
