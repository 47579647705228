/*
 * @Author: jiang
 * @Date: 2021-07-20 16:10:58
 * @Description: 路由拦截
 */
import mm from 'micromatch'
import router from './index'
import store from '@/store/index'
import { whiteRoutes, whiteTagRoutes, indexRoute } from '@/config'

router.beforeEach((to, from, next) => {
  const token = store.state.user.access_token
  if (token) {
    if (to.name === 'login') {
      next({ path: indexRoute, replace: true })
    } else {
      
      if (!mm.isMatch(to.path, whiteTagRoutes) && to.name !== '404') {
        
        store.commit('tag/ADD_TAG', {
          label: to.meta.title,
          path: to.path,
          params: to.params,
          query: to.query,
        })
      }
      next()
    }
  } else {
    if (mm.isMatch(to.path, whiteRoutes)) {

      next()
    } else {
      next({ name: 'login', query: { redirect: to.fullPath }, replace: true })
    }
  }
})

router.afterEach((to, from) => {
 
  window._hmt.push(['_trackPageview', '/#' + to.fullPath])
  if (window._hmt) {
    if (to.path) {
      window._hmt.push(['_trackPageview', '/#' + to.fullPath])
    }
  }
})
