<template>
    <b-page>
        <div v-loading="loading" class="info-box">
            <div class="title">{{ infoData.title }}</div>
            <div class="info">
                <div class="time">
                    <span>发布时间：{{ infoData.created_at }}</span>
                </div>
            </div>
            <div class="content" v-html="infoData.content"></div>

            <div style="margin-left: 35px;">
                <!-- <c-image-list :list="infoData.images"></c-image-list> -->
                <div class="c-media-list">
                    <div class="c-media-item" v-for="item in infoData.media" :key="item.id"
                        @click="handleEditFile(item)">
                        <div style="display: flex;">
                            <el-image class="icon" :src="handlerImageUrl(item.extension)"></el-image>
                            <div class="text g-text-ellipsis">{{ item.original_filename || item.file_name }}</div>
                            <div class="ext">.{{ item.extension }}</div>
                        </div>
                        

                        <div class="btn-box">
                            {{ fileSizeMB(item.size) }}MB
                            <!-- <div class="btn" target="_blank" @click="(e) => handleEditFile(e, item)">
                                预览
                            </div> -->




                        </div>
                    </div>
                    <div>

                    </div>
                </div>
            </div>


            <!-- <div class="footer">
        <div>附件</div>
        <div></div>
      </div> -->
        </div>
    </b-page>
</template>

<script>

import { getNoticDetail } from '@/api/news'

export default {
    components: {

    },
    data() {
        return {
            id: null,
            loading: false,
            visible: false,
            infoData: {},
        }
    },

    watch: {
        '$route.query.id': {
            handler(id) {
                if (id) {
                    this.id = id
                    this.fetchData()
                }
            },
            immediate: true,
        },
    },
    methods: {
        fileSizeMB(size){
            
            return size ? (size / 1024 / 1024).toFixed(2) : 0;
        },
        handlerImageUrl(ext) {
            const extMap = {
                jpg: 'img',
                gif: 'img',
                png: 'img',
                jpeg: 'img',
                csv: 'excel',
                xls: 'excel',
                xlsx: 'excel',
                doc: 'word',
                docx: 'word',
                pdf: 'ppt',
                ppt: 'ppt',
                txt: 'word',
                text: 'word',
                bmp: 'midea',
                mp4: 'midea',
                mp3: 'midea',
                wav: 'midea',
            }
            const icon = extMap[ext] || 'icon'
            return `/images/${icon}.png`
        },
        fetchData() {
            this.loading = true
            getNoticDetail(this.id)
                .then(res => {
                    this.infoData = res
                })
                .finally(() => {
                    this.loading = false
                })
        },
        onLink(url) {
            if (url) {
                window.open(url)
            }
        },
        handleEditFile(item) {
            console.log(item)

            // let url = this.$router.resolve({
            //     path: '/manager/office',
            //     query: Object.assign(item, this.other)
            // })

            window.open(item.url, '_blank')
        },
    },
}
</script>

<style lang="scss" scoped>
.info-box {
    min-height: 240px;

    .title {
        margin-bottom: 15px;
        font-size: 18px;
        font-weight: bold;
        color: #2b2d42;
        text-align: center;
        word-break: break-all;
    }

    .time {
        display: flex;
        padding-bottom: 20px;
        font-size: 12px;
        color: #747e8f;
        border-bottom: 1px solid #dfe3e8;
    }

    .content {
        padding: 15px;
    }

    .footer {
        padding: 10px;
        margin-top: 10px;
        border-top: 1px solid #dfe3e8;
    }
}

.c-media-list {


    .c-media-item {
        position: relative;
       
        align-items: center;
        padding: 8px 12px;
        overflow: hidden;
        font-size: 14px;
        color: #2b2d42;
        background-color: #fafafa;
        border-radius: 5px;
        cursor: pointer;
        margin-bottom: 12px;

        .icon {
            width: 26px;
            height: 26px;
            margin-right: 8px;
        }

        .text {
            font-size: 16px;
            color: #409eff;
            cursor: pointer;
            margin-bottom: 8px;
        }

        .ext {
            color: #409eff;
        }

        .btn-box {
            // position: absolute;
            // top: 0;
            // left: 0;
            // display: flex;
            // align-items: center;
            // justify-content: flex-end;
            // width: 100%;
            // height: 100%;
            margin-left: 35px;
            color: #909399;


            .btn {
                padding: 5px;
                margin-right: 5px;
                font-size: 16px;
                line-height: 1;
                color: #909399;
                background-color: #fff;
                border-radius: 5px;
            }
        }

        // &:hover {
        //     .btn-box {
        //         opacity: 1;
        //     }
        // }
    }
}
</style>