import { render, staticRenderFns } from "./app-menu-horizontal.vue?vue&type=template&id=018de8d6&scoped=true"
import script from "./app-menu-horizontal.vue?vue&type=script&lang=js"
export * from "./app-menu-horizontal.vue?vue&type=script&lang=js"
import style0 from "./app-menu-horizontal.vue?vue&type=style&index=0&id=018de8d6&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "018de8d6",
  null
  
)

export default component.exports